import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container" style={{marginTop:'80px', justifyContent: 'center'}}>
      <div className="row" style={{justifyContent: 'center'}}>
        <div className="col-md-5" style={{textAlign: 'center', border: '2px solid #FDF8E8', padding: '30px 0px', backgroundColor: 'rgba(0, 0, 0, 0.15)'}}>
          <h1 style={{color: '#FDF8E8', marginBottom: '10px'}}>Cassandra Sessa</h1>
          <h3 style={{color: '#FDF8E8', fontWeight: 'lighter'}}>Aspiring Producer / Director</h3>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
